import * as React from "react";

import s from "./Row.scss";

interface IRowProps {
  className?: string;
  children: React.ReactNode;
}

export const Row = ({ className, children }: IRowProps) => {
  console.log(className);

  return <div className={`${s.row} ${className}`}>{children}</div>;
};
