import * as React from "react";
import s from "./Terminal.scss";
import Draggable from "react-draggable"; // The default
// import { ResizableBox } from "react-resizable";
import { isMobile } from "react-device-detect";

interface Terminal {
  className?: string;
  children: React.ReactNode;
  path: string;
  command: string;
  footer: React.ReactNode;
}

let tvElement: React.RefObject<HTMLDivElement> = React.createRef();

// function generateNoise(opacity: any, h: any, w: any) {
//   function makeCanvas(h: any, w: any) {
//     var canvas = document.createElement("canvas");
//     canvas.height = h;
//     canvas.width = w;
//     return canvas;
//   }

//   function randomise(data: any, opacity: any) {
//     // see prev. revision for 8-bit
//     var i, x;
//     for (i = 0; i < data.length; ++i) {
//       x = Math.floor(Math.random() * 0xaaaaaa); // random RGB
//       // console.log(x);
//       data[i] = x | opacity; // set all of RGBA for pixel in one go
//     }
//   }

//   function initialise(opacity: any, h: any, w: any) {
//     const canvas = makeCanvas(h, w);
//     const context = canvas.getContext("2d");

//     console.log("TV", tvElement);
//     if (!context || !tvElement) {
//       return;
//     }

//     const image = context.createImageData(h, w);
//     const data = new Uint8Array(image.data.buffer);
//     opacity = Math.floor(opacity * 0x255) << 24; // make bitwise OR-able
//     return function () {
//       randomise(data, opacity); // could be in-place for less overhead
//       context!.putImageData(image, 0, 0);
//       // you may want to consider other ways of setting the canvas
//       // as the background so you can take this out of the loop, too
//       if (!tvElement || !tvElement.current) {
//         return;
//       }
//       tvElement.current.style.backgroundImage =
//         "url(" + canvas!.toDataURL("image/png") + ")";
//     };
//   }

//   return initialise(opacity || 0.2, h || 55, w || 55);
// }

// var noise = generateNoise(0.5, 15, 10);

// (function loop() {
//   if (noise) {
//     noise();
//   }

//   setTimeout(loop, 2000);
//   // requestAnimationFrame(loop);
// })();

export const Terminal = ({ children, path, command, footer }: Terminal) => {
  const [closed, setClosed] = React.useState(false);
  const [maximized, setMaximized] = React.useState(false);

  const [position, setPosition] = React.useState({
    x: 0,
    y: 0,
  });

  function close() {
    if (!maximized) {
      setClosed(true);

      setTimeout(() => {
        setClosed(false);
      }, 1000);
      return;
    }

    setMaximized(false);
    setPosition({ x: 0, y: 0 });
  }

  // function maximize() {
  //   setPosition({ x: 0, y: 0 });

  //   // tvElement.current.
  //   console.log("clicked");
  //   setMaximized(true);

  //   // setTimeout(() => {
  //   //   setMaximized(false);
  //   // }, 1000);
  // }

  return (
    <Draggable handle=".handle" defaultPosition={position} disabled={isMobile}>
      <div
        style={{
          width: "100%",
          // ...(maximized && {
          //   heigt: "100%",
          //   width: "100%",
          //   position: "fixed",
          //   top: "0",
          //   left: "0",
          //   bottom: "0",
          //   right: "0",
          // }),
        }}
      >
        <section
          className={`${s.terminal} ${
            closed ? `${s.wobble_horizontal}` : undefined
          }`}
          style={
            {
              // ...(maximized && {
              //   // transform: "translate(180px, -2px)",
              //   heigt: "100%",
              //   width: "100%",
              //   position: "fixed",
              //   top: "0",
              //   left: "0",
              //   bottom: "0",
              //   right: "0",
              // }),
            }
          }
        >
          <div className={`${s.terminal__window} handle`}>
            <div className={s.terminal__button} onClick={close}>
              <div className={s.terminal__button__close}></div>
            </div>
            <div className={s.terminal__button}>
              <div className={s.terminal__button__minimize}></div>
            </div>
            <div className={s.terminal__button}>
              <div className={s.terminal__button__size}></div>
            </div>
          </div>
          <div className={s.terminal__inner} ref={tvElement}>
            <header>
              <section className={s.terminal__exec}>
                <div className={s.terminal__flag}>
                  <span>{path}</span>
                </div>
                <div className={s.terminal__command}>
                  <span>{command}</span>
                </div>
              </section>
            </header>
            <article className={s.terminal__content}>{children}</article>
            <footer className={s.terminal__footer}>
              <section>{footer}</section>
            </footer>
          </div>
        </section>
      </div>
    </Draggable>
  );
};
