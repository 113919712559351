import * as React from "react";
import Helmet from "react-helmet";
import { Resume } from "../components/resume/Resume";
import { graphql } from "gatsby";

export default ({ data }: { data: any }) => {
  console.log("xx", data);
  return (
    <>
      <Helmet title="Resume" />
      <Resume
        schematic={data.schematic.childImageSharp}
        selfImage={data.selfImage.childImageSharp}
        logos={data.logos.childImageSharp}
      ></Resume>
    </>
  );
};

export const pageQuery = graphql`
  query SelfImage {
    selfImage: file(relativePath: { regex: "/self/" }) {
      childImageSharp {
        fluid {
          src
          sizes
          srcSet
          srcWebp
          aspectRatio
        }
      }
    }

    schematic: file(relativePath: { regex: "/schematics/" }) {
      childImageSharp {
        fluid {
          src
          sizes
          srcSet
          srcWebp
          aspectRatio
        }
      }
    }

    logos: file(relativePath: { regex: "/logos/" }) {
      childImageSharp {
        fluid {
          src
          sizes
          srcSet
          srcWebp
          aspectRatio
        }
      }
    }
  }
`;
