import * as React from "react";

import "./DarkTheme.scss";

interface DarkTheme {
  className?: string;
  children: React.ReactNode;
}

export const DarkTheme = ({ className, children }: DarkTheme) => {
  return <div className={`${className ? className : ""}`}>{children}</div>;
};
