import * as React from "react";

import s from "./Container.scss";

interface IContainerProps {
  className?: string;
  children: React.ReactNode;
}

export const Container = ({ className, children }: IContainerProps) => (
  <section className={`${s.container} ${className ? className : ""}`}>
    {children}
  </section>
);
