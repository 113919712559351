import * as React from "react";
import { Container } from "../container/Container";
import { DarkTheme } from "../dark-theme/DarkTheme";
import { Row } from "../row/Row";
import Relive from "assets/images/logo-relive.png";
import Datlinq from "assets/images/datlinq.png";
import WebFirst from "assets/images/webfirst.png";
import Lightspeed from "assets/images/lightspeed.png";
import Image from "gatsby-image";

import s from "./Resume.scss";
import { Terminal } from "../terminal/Terminal";

interface Resume {
  className?: string;
  selfImage: any;
  schematic: any;
  logos: any;
  //   children: React.ReactNode;
}

export const Resume = ({ selfImage, schematic, logos }: Resume) => (
  <>
    <div className={s.background}>
      <Container className={s.container}>
        <div className={s.line}></div>
      </Container>
    </div>

    <DarkTheme>
      <Container>
        <Row className={`${s.margin__t} ${s.margin__b} ${s.column_reverse__m}`}>
          <article className={`${s.col__7} ${s.col__12__m} ${s.margin__ts__m}`}>
            <header>
              <h1>A bit about me</h1>
              <span className="subtext">Last updated at</span>
              <span className="divider"></span>
              <span>December 3rd 2020</span>
            </header>
            <p>
              My name is Niklas Kors. A very versatile do-it-all kinda guy. With
              my knowledge of newly emerged technologies like Node.js, Angular/
              React and AWS/Google Cloud I’m able to work on every aspect of web
              related projects. Present me with a technical challenge and I’m
              sure I’ll come up with a plan to implement the solution.
            </p>
            <div className={s.margin__t}>
              <a
                href="mailto:me@niklaskors.com"
                className="button hvr-underline-from-left"
              >
                Contact me
              </a>
            </div>
          </article>
          <div className={`${s.col__5} ${s.v_center} ${s.col__12__m}`}>
            <div style={{ width: "100%" }}>
              {selfImage && (
                <Image
                  title="Niklas Kors"
                  alt="Picture of myself"
                  fluid={selfImage.fluid}
                  fadeIn={true}
                />
              )}
            </div>
          </div>
        </Row>

        <Row>
          <Terminal
            path="~/Dev/resume"
            command="cat quick_summary.md"
            footer={
              <>
                <span>*FYI this is my actual iTerm theme. Get it </span>
                <a
                  target="_blank"
                  href="https://github.com/mbadolato/iTerm2-Color-Schemes/blob/master/schemes/Monokai%20Soda.itermcolors"
                >
                  here
                </a>
              </>
            }
          >
            <p># TL;DR Resume</p>
            <ul>
              <li>
                🗓
                <span>
                  <span className="c__blue">8+</span> years of Full-Stack
                  software experience
                </span>
              </li>
              <li>
                ⭐️
                <span>
                  Worked with <span className="c__blue">60+</span> clients
                </span>
              </li>
              <li>
                👨🏼‍💻 <span>I work at a amazing startup called Relive</span>
              </li>
              <li>
                🧙🏼‍♂️ <span>Cloud infrastructure wizard. Terraform + AWS</span>
              </li>
              <li>
                💻
                <span>
                  Go to programming language = JavaScript/TypeScript + Node.js
                </span>
              </li>
              <li>
                💡
                <span>
                  <span className="c__yellow">Certified</span> AWS Solutions
                  Architect
                </span>
              </li>
              <li>
                🇳🇱 <span>Currently located in the Netherlands</span>
              </li>
            </ul>
          </Terminal>
        </Row>
      </Container>

      <div className={s.background_container_row}>
        <div className={s.black_background_row}></div>
      </div>

      <Container>
        <Row>
          <article className={s.col__12}>
            <header>
              <h2>The things I know</h2>
              <span className="fat_subtitle">(Lot's of things I do not)</span>
            </header>
            <p>
              Quite a while ago when I was at the age of sixteen I started out
              with programming in PHP and C#. Although I enjoyed these back
              then, it turned out that using JavaScript across the entire stack
              proofed itself to be more powerful. Especially when combined with
              strong typings provided by TypeScript. Needles to say JavaScript
              is now my go-to programming language and thanks to this I’m now
              able to easily switch through different disciplines of software
              development.
            </p>
          </article>
        </Row>
        <Row className={`${s.margin__t} ${s.margin__b} ${s.column_reverse__m}`}>
          <article
            className={`${s.col__3} ${s.v_center} ${s.col__12__m} ${s.margin__t__m}`}
          >
            <p>
              A sample of a powerful all- JavaScript setup within AWS. The power
              of this setup is that it scales extremly well and everything can
              be maintained using the same programming language.
            </p>
          </article>
          <div className={`${s.col__9} ${s.col__12__m}`}>
            <div className={s.schematics}>
              <div style={{ width: "100%" }}>
                {schematic && (
                  <Image
                    title="Schematic"
                    alt="Schematic overview"
                    fluid={schematic.fluid}
                    fadeIn={true}
                  />
                )}
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <article className={`${s.col__8} ${s.col__12__m}`}>
            <p>
              While having the most experience with setups similar to the above
              I’m also able to work on application and infrastructure projects
              that include other technologies. I listed some of the technologies
              I’m most familiar with below.
            </p>
            <Row className={s.margin__t}>
              <section className={s.col__6}>
                <h5>Infrastructure</h5>
                <ul>
                  <li>- AWS</li>
                  <li>- Terraform</li>
                  <li>- Kubernetes</li>
                  <li>- Debian GNU/linux</li>
                  <li>- GCP</li>
                </ul>
              </section>
              <section className={s.col__6}>
                <h5>Applications</h5>
                <ul>
                  <li>- Node.js</li>
                  <li>- Angular &gt;2.x</li>
                  <li>- React SPA/Native</li>
                  <li>- PHP</li>
                  <li>- C#</li>
                </ul>
              </section>
            </Row>
          </article>
          <div className={`${s.v_center} ${s.col__4} ${s.col__12__m}`}>
            <div style={{ width: "100%" }} className={`${s.tech_logos}`}>
              {logos && (
                <Image
                  title="Logos"
                  alt="Logos"
                  fluid={logos.fluid}
                  fadeIn={true}
                  imgStyle={{
                    objectFit: "contain",
                    // objectPosition: "50% 50%",
                  }}
                  // style={{ maxWidth: "100%" }}
                  // imgStyle={{
                  //   maxWidth: "100%",
                  // }}
                />
              )}
            </div>
          </div>
        </Row>
      </Container>

      <div className={s.background_container}>
        <Container>
          <Row>
            <article className={`${s.col__10} ${s.col__12__m}`}>
              <header>
                <h2>Work experience</h2>
                <span className="fat_subtitle">
                  Ranging from startups to enterprise
                </span>
              </header>
              <p>
                I worked for a variety of different companies as well as for my
                own marketing agency called Web First. At Web First we provided
                small to medium sized businesses with websites/webshops/SPA’s as
                well as SEO optimizations. At our peak we worked on these
                projects with a team of 10 (very) young professionals. At this
                stage we used to host all of our websites together with email
                accounts on our own dedicated servers. However, during my time
                at Web First I was still in the process of getting my degree
                which required me to do an internship for six months.
                <br /> <br />
                This internship which I completed at Lightspeed showed me the
                beauty of working on larger applications. So after Lightspeed I
                decided to join Datlinq to gain some additional experience with
                writing application code.
                <br />I decided to take this to the next level by joining a fast
                growing startup called Relive. This startup let’s their users
                create videos of their outdoor activities through it’s own
                IOS/Android app. When I joined, Relive already had over 2
                million users and was creating ~250K videos a week. Currenlty
                Relive has +9 million users and we now produce over 200K videos
                in a single day! I mainly worked on scaling the infrastructure
                to this level as well as adding new features on the backend.
              </p>
            </article>
          </Row>

          <div className={s.logos}>
            <Row>
              <section className={`${s.col__3} ${s.col__12__m}`}>
                <img src={Relive} alt="Relive logo" />
              </section>
              <article className={`${s.col__9} ${s.col__12__m}`}>
                <section>
                  <h5>Full-stack developer - Relive | Rotterdam</h5>
                  <span className="">From jun 2018 - today</span>
                </section>
              </article>
            </Row>
            <Row>
              <section className={`${s.col__3} ${s.col__12__m}`}>
                <img src={Datlinq} alt="Datlinq logo" />
              </section>
              <article className={`${s.col__9} ${s.col__12__m}`}>
                <section>
                  <h5>Full-stack developer - Datlinq | Rotterdam</h5>
                  <span className="">From sep 2017 - jun 2018</span>
                </section>
              </article>
            </Row>
            <Row>
              <section className={`${s.col__3} ${s.col__12__m}`}>
                <img src={WebFirst} alt="Web First logo" />
              </section>
              <article className={`${s.col__9} ${s.col__12__m}`}>
                <section>
                  <h5>Managing partner - Web First | Heerhugowaard</h5>
                  <span className="">From jan 2013 - dec 2017</span>
                </section>
              </article>
            </Row>
            <Row>
              <section className={`${s.col__3} ${s.col__12__m}`}>
                <img src={Lightspeed} alt="Lightspeed logo" />
              </section>
              <article className={`${s.col__9} ${s.col__12__m}`}>
                <section>
                  <h5>Front-end developer intern - Lightspeed | Gent</h5>
                  <span className="">From jan 2017 - jun 2017</span>
                </section>
              </article>
            </Row>
          </div>
        </Container>
      </div>

      <Container>
        <Row>
          <article className={s.col__12}>
            <header>
              <h2>Education</h2>
            </header>
            <section className={s.margin__b}>
              <h4>HBO - Business IT & Management - INholland | Alkmaar</h4>
              <span className="subtext">From 2013 - 2017</span>
              <p>
                Subjects: Web Markup, Web Design, Networking, Process Modeling,
                ICT Management, Internet Technology, Computer Architecture, Data
                Modeling, BIV, C#.NET, Marketing, Databases, ASP.NET, AO, UML,
                XML, SQL Server, Data warehousing.
              </p>
            </section>

            <section className={s.margin__b}>
              <h4>
                Highschool - Senior General Secondary Education - Han Fortmann
                College | Heerhugowaard
              </h4>
              <span className="subtext">From 2011 - 2013</span>
              <p>
                Subjects: Computer Science, Mathematics, Physics, Chemistry,
                Music, English, Dutch, German, Social Studies, Philosophy, ICT.
              </p>
            </section>

            <section className={s.margin__b}>
              <h4>
                Highschool - Senior General Secondary Education -
                Elzendaalcollege | Boxmeer
              </h4>
              <span>From 2010 - 2011</span>
              <p>
                Subjects: Computer Science, Mathematics, Physics, Chemistry,
                Music, English, French Dutch, German, Social Studies,
                Philosophy, ICT.
              </p>
            </section>

            <section className={s.margin__b}>
              <h4>
                Highschool - Senior General Secondary Education - Realschule |
                Kleve
              </h4>
              <span>From 2008 - 2010</span>
            </section>
          </article>
        </Row>
      </Container>

      <footer className={s.footer}>
        <Container>
          <Row className={s.footer__inner}>
            <section className={`${s.col__6} ${s.divider} ${s.col__12__m} `}>
              <span className={`bold ${s.footer__inner__header}`}>
                Contact details
              </span>
              <br></br>
              <Row>
                <section className="col__5">
                  <span>Email</span>
                </section>
                <section className="col__7">
                  <a href="mailto:me@niklaskors.com">me@niklaskors.com</a>
                </section>
              </Row>
              <Row>
                <section className="col__5">
                  <span>LinkedIn</span>
                </section>
                <section className="col__7">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/niklaskors/"
                  >
                    niklaskors
                  </a>
                </section>
              </Row>
            </section>
            <section className="col__6 col__12__m"></section>
          </Row>
        </Container>
      </footer>
    </DarkTheme>
  </>
);
